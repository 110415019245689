.field-name {
  color: #4e4e4e;
  font-size: 14px;
}

.field-name-big {
  font-size: 14px;
}

.field-value {
  display: flex;
  clear: both;
  font-weight: bold;
  color: #4e4e4e;
  margin-bottom: 8px;
  a {
    font-size: 12px;
    color: #4e4e4e;
  }
}

.amagi-card {
  height: 100%;
  padding: 16px;
  background-color: #fff;
  border-radius: 5px;
  // border-radius: 4px;
  .amagi-card-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .amagi-card-title {
      font-size: 18px;
      color: #4e4e4e;
      margin: 0;
      font-weight: normal;
    }
    .amagi-card-icon {
      // border-radius: 100%;
      width: 32px;
      height: 32px;
    }
  }
  .amagi-card-body {
    p {
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }
  }
}

.data-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  height: 100%;
  h3 {
    color: #4e4e4e;
    font-size: 28px;
    line-height: 1.6;
    margin: 0;
  }
  .data-card-body {
    h4 {
      color: #4e4e4e;
      margin: 0;
      font-size: 20px;
      font-weight: normal;
    }
  }
  .data-card-header {
    margin-top: 5px;
    .data-card-icon {
      margin-right: 15px;
    }
    .data-card-count {
      font-size: 40px;
    }
  }
}
