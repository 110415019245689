.table-container {
  /* margin: 1rem 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container table {
  border-spacing: 0;
  /* border: 1px solid var(--neutral-stone-500); */
  width: 100%;
  border-collapse: separate;
}

/* .table-container table tr td :last-child {
  border-bottom: 0;
} */

.table-container table th,
td {
  margin: 0;
  font-size: 14px;
  padding: 10px 15px;
  /* border: 1px solid black; */
  text-align: center;
  /* color: #4E4E4E; */
}

.view_button {
  background: none;
  color: #4e4e4e;
  margin: auto;
}

.table_card {
  border: 1px solid var(--neutral-stone-500);
  color: #4e4e4e;
  padding: 25px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.custom_container {
  margin: 0;
}

.amagi-modal-body {
  height: 100%;
}

.input_search {
  margin: 10px 0px 10px 0px;
  /* border: 1px solid var(--neutral-stone-500); */
  /* border-radius: 5px; */
}

.input_search input {
  color: #4e4e4e !important;
  min-height: 40px;
  padding: 15px 0px;
}

.input_search .amagi-icon-expand:before {
  content: none;
}

.text_box {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.text_box::-webkit-scrollbar {
  display: none;
}

.text_box h4 {
  color: white;
  font-weight: normal;
}

.text_box h4 b {
  margin-right: 10px;
}

.text_box ul {
  margin-top: 0;
  list-style: none;
  width: 50%;
  float: left;
}

.text_box ul li {
  color: #fff;
  padding-bottom: 10px;
}

.form-mb {
  display: block;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.pagination button {
  margin-left: 10px;
}

.pagination .page-index {
  margin: 5px;
  /* color: #4E4E4E; */
}

/* .alignRight {
  text-align: right !important;
} */

input[type="number"] {
  text-align: right !important;
}

iframe {
  display: block !important;
  pointer-events: all !important;
}

.amagi-datepicker .amagi-date-picker-custom {
  color: black !important;
  border-bottom: 1px solid black !important;
}

.amagi-datepicker .amagi-date-picker-custom .amagi-icon,
.amagi-date-picker-calendar .react-datepicker__header,
.amagi-date-picker-calendar .react-datepicker__month-text {
  color: black !important;
}

.amagi-date-picker-calendar {
  background: #fff !important;
}

.amagi-date-picker-calendar .react-datepicker__month--selected,
.amagi-date-picker-calendar .react-datepicker__month-text:hover {
  background: lightgray !important;
}

.amagi-date-picker-calendar .react-datepicker__navigation {
  background: none !important;
}

.amagi-date-picker-calendar
  .react-datepicker__navigation-icon--previous::before,
.amagi-date-picker-calendar .react-datepicker__navigation-icon--next::before {
  top: 12px !important;
}

.react-tooltip {
  max-width: 400px !important;
}

.amagi-login-page-container .amagi-background-slider .amagi-slider-image {
  background-repeat: round !important;
}

.amagi-login-page-container
  .amagi-background-slider
  .amagi-slider-image
  .amagi-slider-description {
  color: white !important;
}

.amagi-progress .amagi-progress-parentdiv {
  border-radius: 3px !important;
  width: 124px !important;
  height: 16px !important;
  background: white !important;
}

.amagi-progress .amagi-progress-text {
  color: black !important;
}

/* .pagination select {
  background-color: #4E4E4E;
  color: #fff;
} */

.tooltip {
  max-width: 1000px !important;
}

.google-visualization-tooltip {
  display: none !important;
}

/* React Select Custom Styles */

.css-g37w59-control {
  border: 0px !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0px !important;
}

.css-1ha0t1r-control {
  border: 0px !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0px !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

/* .reactgooglegraph-1:has(path) {
  
} */
/* TimeLine Component */
.timeline {
  margin: 90px 60px;
  height: 4px;
  background-color: #ccc;
}

.timeline-progress {
  height: 100%;
  background-color: #67b045;
}

.timeline-items {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
}

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #ccc;
  display: block;
  border-radius: 100%;
}

.timeline-content {
  white-space: nowrap;
  font-size: 14px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.timeline-content-date {
  white-space: nowrap;
  font-size: 14px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.timeline-content-reason {
  max-width: 150px;
  white-space: nowrap;
  /* overflow-x: scroll; */
  font-size: 14px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.timeline-content::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ddd;
  display: block;
  position: absolute;
  top: -8px;
  margin-left: -2px;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
}

.timeline-item.active::before {
  background-color: #67b045;
}

.even {
  top: -50px;
}

.even::before {
  top: 22px;
}

.timeline-item.active .timeline-content {
  color: #fff;
  background-color: #67b045;
}

.timeline-item.active .timeline-content::before {
  background-color: #67b045;
}

table.roundedCorners {
  /* border: 1px solid DarkOrange; */
  border-radius: 13px;
  border-spacing: 0;
}
table.roundedCorners td,
table.roundedCorners th {
  /* border-bottom: 1px solid DarkOrange; */
  padding: 10px;
}
table.roundedCorners tr:last-child > td {
  border-bottom: none;
}

.rs-stack-item .rs-picker-toolbar-right button[type="button"] {
  background-color: #3498ff;
}

.rs-picker-toggle-value,
.rs-picker-toggle-textbox {
  color: gray !important;
  font-weight: bold;
}

.rs-picker-daterange-menu {
  z-index: 999 !important;
}

.css-qbdosj-Input input {
  --tw-ring-shadow: 0 !important;
}
