iframe {
  pointer-events: none;
  display: none;
}
html {
  scroll-behavior: smooth;
}

.full-width {
  width: 100%;
}

.mb-16 {
  margin-bottom: 16px;
}

.customer-page-container {
  // padding-top: 16px;
  padding-bottom: 24px;
  h2 {
    font-size: 18px;
    line-height: 1.6;
    color: #4e4e4e;
    margin-bottom: 16px;
    margin-top: 0px;
    font-weight: 400;
  }
}
.nav {
  margin-top: 16px;
  position: sticky;
  top: 0;
  .nav-links-container {
    border-radius: 4px;
    display: flex;
    background: var(--neutral-stone-900);
    justify-content: space-between;
    .btn {
      border: 0 !important;
      text-decoration: none;
    }
    .btn-primary-outline {
      color: var(--brand-orange-500);
    }
  }
}

.toggle-form {
  .amagi-icon-expand {
    transform: rotate(45deg);
  }
}
