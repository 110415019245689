[class*="amagi-theme"] {
  background-color: #f2f2f2;
  min-height: 100vh;
}

.form-field-vmargin {
  margin-bottom: 1rem;
}

table {
  width: 100%;
}

/* .amplify-tabs {
  display: none;
} */

/* #radix-id-0-2-trigger-1 {
  display: none;
}
 */

.text-sm {
  font-size: 0.8rem;
}
.text-normal {
  font-weight: normal;
}

data-amplify-router {
  display: none;
}

.amagi-login-page-container .amagi-login-page-header .amagi-header-text * {
  color: #fff !important;
}
