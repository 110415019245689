@tailwind base;
@tailwind components;
@tailwind utilities;

@import "rsuite/dist/rsuite.css";

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#login-footer {
  text-align: center;
  /* margin-top: 12px; */
  /* color: #fff; */
  font-size: 0.9rem;
  /* text-decoration: underline; */
}
